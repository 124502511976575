import { defineNuxtPlugin } from '#app'; // Correct Nuxt 3 syntax
import VueLazyLoad from 'vue3-lazyload';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueLazyLoad, {
        preLoad: 1.3,
        loading: null,
        error: null,
        lifecycle: {
            loading: (el) => {
                //console.log('Loading started', el);
            },
            loaded: (el) => {
                //console.log('Loaded successfully', el);
            },
            error: (el) => {
                //console.log('Loading error', el);
            },
        },
    });
});
