import { defineStore } from 'pinia';

type Panel = 'menu' | 'search' | 'filter' | 'travel';

interface GeolocationState {
    latitude: number | null;
    longitude: number | null;
}

interface User {
    id: string;
    name: string;
    email: string;
    photo?: string;
    phone?: string;
}

interface PanelState {
    menu: boolean;
    search: boolean;
    filter: boolean;
    travel: boolean;
}

interface Exploration {
    id: number,
    label: string
}

interface StoreState {
    panels: PanelState;
    aroundMe: boolean;
    isLogged: boolean;
    user: User | null;
    travel: Travel;
    countInteraction: number;
    geolocationStatus: 'idle' | 'loading' | 'success' | 'error';
    currentPosition: GeolocationState;
    exploration: Exploration;
}

interface Travel {
    hotelSelectedId: number | null;
    hotelSelectedPrice: number | null;
    totalPrice: number;
    activityPrice: any;
}

export const useStore = defineStore('store', {
    state: (): StoreState => ({
        panels: {
            menu: false,
            search: false,
            filter: false,
            travel: false
        },
        travel: {
            hotelSelectedId: null,
            hotelSelectedPrice: null,
            activityPrice: [],
            totalPrice: 0
        },
        isLogged: false,
        user: null,
        countInteraction: 0,
        aroundMe: false,
        geolocationStatus: 'idle',
        currentPosition: { latitude: null, longitude: null },
        exploration: {
            id: 74,
            label: 'France'
        }
    }),

    actions: {
        togglePanel(panel: Panel) {
            Object.keys(this.panels).forEach((key) => {
                this.panels[key as Panel] =
                    key === panel ? !this.panels[key as Panel] : false;
            });
        },

        closeAllPanels() {
            Object.keys(this.panels).forEach((key) => {
                this.panels[key as Panel] = false;
            });
        },

        enableAroundMe() {
            this.aroundMe = true;
            const aroundMeCookie = useCookie('aroundMe');
            aroundMeCookie.value = 'true';
        },

        disableAroundMe() {
            this.aroundMe = false;
            const aroundMeCookie = useCookie('aroundMe');
            aroundMeCookie.value = 'false';
            this.resetCoordinates();
        },

        toggleAroundMe() {
            console.log('Toggling around me...');
            if (this.aroundMe) {
                this.disableAroundMe();
            } else {
                this.enableAroundMe();
            }
        },

        setCoordinates(latitude: number, longitude: number) {
            this.currentPosition = { latitude, longitude };
        },

        resetCoordinates() {
            this.currentPosition = { latitude: null, longitude: null };
        },

        onPositionChange({ latitude, longitude }: GeolocationState) {
            console.log('Position changed:', { latitude, longitude });
        },

        async setUserData(user: User) {
            this.user = user;
            this.isLogged = true;
        },

        async setPhoto(url: string) {
            if (this.user) {
                this.user.photo = url;
            } else {
                console.error('Cannot set photo: User is not set.');
            }
        },

        async setUsername(name: string) {
            if (this.user) {
                this.user.name = name;
            } else {
                console.error('Cannot set username: User is not set.');
            }
        },

        async setLogged(value: boolean) {
            this.isLogged = value;
            if (!value) {
                this.user = null;
            }
        },

        async incrementInteraction() {
            this.countInteraction++;
        },
        async setHotelSelected(id: number, price: number) {
            this.travel.hotelSelectedId = id;
            this.travel.hotelSelectedPrice = price;
        },
        async setTotalTravelPrice(price: number) {
            this.travel.totalPrice = price;
        },
        async addActivityPrice(id: number, price: number) {
            this.travel.activityPrice[id] = price;
        },
        async setExploration(id, label) {
            this.exploration = { "id": id, "label": label };
        }
    },
    persist: true
});
