import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Vue3Toastify, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        theme: 'dark',
        hideProgressBar: true,
        closeOnClick: true,
        className: 'custom-dark-toast',
    });
});
