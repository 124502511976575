export default defineNuxtRouteMiddleware(async () => {
    if (import.meta.client) return;

    const store = useStore();
    const user = useSupabaseUser();

    try {
        if (user.value?.role === 'authenticated') {
            if (store.isLogged) return;

            const userData = {
                id: user.value.id,
                name:
                    user.value.user_metadata?.full_name ??
                    user.value.email?.split('@')[0] ??
                    '',
                email: user.value.email ?? '',
                photo: user.value.user_metadata?.avatar_url ?? '',
                phone: user.value.user_metadata?.phone ?? '',
            };

            await store.setLogged(true);
            await store.setUserData(userData);
        } else {
            await store.setLogged(false);
        }
    } catch (error) {
        console.error('Auth middleware error:', error);

        await store.setLogged(false);
    }
});
